import { IModule } from './type.d'

export const getListNonAccessModule: any = (
  listAll: IModule[],
  listNonAccess: IModule[]
): any => {
  const listIdNonAccess: string[] = listNonAccess.map((item: IModule): any => {
    return item.id
  })

  const listModuleAll: string[] = listAll?.map((item: IModule): any => {
    return item.parent
  })

  const listModuleAllFilter: string[] = listModuleAll?.filter(
    (item: string, index: number): any => listModuleAll.indexOf(item) === index
  )

  const listAccess: IModule[] = listAll?.filter((item: IModule): any => {
    return !listNonAccess?.some((obj: IModule): any => {
      return item.id === obj.id
    })
  })

  const listModuleAccess: string[] = listAccess?.map((item: IModule): any => {
    return item.id
  })

  const listModuleAccessFilter: string[] = listModuleAccess?.filter(
    (item: string, index: number): any =>
      listModuleAccess.indexOf(item) === index
  )

  const listModuleNonAccess: string[] = listModuleAllFilter?.filter(
    (item: string): any => {
      return !listModuleAccessFilter.includes(item)
    }
  )

  const listModuleNonAccessFilter: string[] = listModuleNonAccess?.map(
    (item: string): any => item?.toLocaleLowerCase()
  )

  let listNonAccessModify: string[] = listModuleNonAccessFilter
  if (listModuleNonAccessFilter?.includes('admin')) {
    listNonAccessModify = [...listNonAccessModify, 'admin_page']
  }
  if (listModuleNonAccessFilter?.includes('support')) {
    listNonAccessModify = [...listNonAccessModify, 'supports']
  }

  return listIdNonAccess?.concat(listNonAccessModify)
}
